import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function CityTourDos() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'taganga.jpg', titulo: 'TAGANGA', contenido: 'En Taganga, un pueblo de pescadores situado a quince minutos al norte de Santa Marta en una bahía rodeada de montañas, los viajeros pueden aprender a bucear en varios centros certificados. Aquí se consiguen hoteles y restaurantes de comida típica, y por las tardes es posible observar unas puestas de sol conmovedoras sobre el mar.' },
         ];


    return (
        <div >

        <FormularioTour titulo='Santa Marta-City Tour' >
                <div>
                    <div className='col-12 col-sm-8 mx-auto mb-4' ><img width='100%' src={require("../img/tours/citytour.jpg")}></img></div>
                    <p style={{ textAlign: 'justify' }}>Santa Marta, oficialmente Distrito Turístico, Cultural e Histórico de Santa Marta, es la capital del departamento del Magdalena, Colombia. Fundada el 29 de julio de 1525 por el conquistador español Rodrigo de Bastidas, es la ciudad más antigua existente de Colombia y la tercera más antigua de Sudamérica La ciudad utiliza como eslogan turístico «la magia de tenerlo todo» ya que tiene más de cien playas y una sierra nevada.</p>
                    <ul>
                        <li><span >Quinta de San Pedro Alejandrino</span></li>
                        <li><span >Monumento del Pibe</span></li>
                        <li><span >Bahía de Santa Marta</span></li>
                        <li><span >La Catedral</span></li>
                        <li><span >Centro Histórico</span></li>
                        <li><span >Museo del Oro Tayrona</span></li>
                        <li><span >Mirador de Taganga</span></li>
                    </ul>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='9:00 am o 2:00 pm' titulo='Horas;' >
                <div>              
                    <p>Contáctenos</p>
                </div>
            </FormItinerario>
        </div>
    )

}
import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function DonDiego() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'dondiego2.jpg', titulo: 'TOUR DON DIEGO - PALOMINO', contenido: 'Paseo por neumáticos en el Rio Don Diego, donde podrán ver los monos aulladores y diferentes especies de aves, terminando el recorrido en la playa y regresando en lancha.' },
        { id: 2, folder: 'tours', imagen: 'dondiego.jpg', titulo: 'RIO DON DIEGO', contenido: 'El río Don Diego nace de los nevados; en su corto recorrido no recibe residuos químicos ni contaminación, lo que lo convierte en un río limpio para recrearse en él ya sea con la simple observación, pasear en flotadores, o haciendo kayack o tubing.' },
        { id: 3, folder: 'tours', imagen: 'palomino.jpg', titulo: 'PALOMINO', contenido: 'Está ubicado sobre la Transversal del Caribe, a orillas del Río Palomino, cerca de la costa del Mar Caribe. Palomino es el punto limítrofe entre La Guajira y el Departamento del Magdalena. A 72 km de Santa Marta. Su territorio tiene todos los pisos térmicos, desde el nivel del mar hasta las nieves perpetuas de la Sierra Nevada de Santa Marta. Limita al norte con el Mar Caribe, al oriente con el corregimiento de Rioancho, al sur con el Departamento de Cesar y al oeste con el Departamento del Magdalena.' }
    ];


    return (
        <div >

            <FormularioTour titulo='TOUR DON DIEGO PALOMINO'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='9:00am - 5:00 pm' titulo='Incluye' >
                <div>
                    <ul>
                        <li>transporte en vehículo climatizado ida y regreso</li>
                        <li>paseo por el río don diego en neúmaticos hasta la desembocadura</li>
                        <li>Regreso en lancha, donde seguiremos en el vehículo hasta palomino, si usted desea disfrutar del baño y de una vista a tan hermosas playas</li>
                        <li>Neumaticos (opcional)</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}
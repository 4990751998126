import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Gairaca() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'gayraca2.jpg', titulo: 'PLAYA GAIRACA', contenido: 'Se encuentra entre Bahía Concha y Neguanje y viene con una gran extensión de tierra cubierta de arena muy fina y blanca. Es una playa totalmente vacía y tranquila, recomendada para una visita de un día y especialmente para practicar snorkel o buceo por su gran extensión de arrecifes de coral, peces y vida marina.' },
       ];


    return (
        <div >

            <FormularioTour titulo='TOUR PLAYA GAIRACA'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='Definido con el cliente' titulo='Transporte' >
                <div>
                    <ul>
                        <li>vehículo climatizado de 1 a 4 pax ida y vuelta</li>
                        <li>Van climatizada de 5 pax en adelante ida y vuelta</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}
import image_01 from './../img/tours/barranquilla/01.jpg'
import image_02 from './../img/tours/barranquilla/02.jpg'
import image_03 from './../img/tours/barranquilla/03.jpg'
import image_04 from './../img/tours/barranquilla/04.jpg'
import image_05 from './../img/tours/barranquilla/05.jpg'
import image_06 from './../img/tours/barranquilla/06.jpg'
import image_07 from './../img/tours/barranquilla/07.jpg'
import image_08 from './../img/tours/barranquilla/08.jpg'



export const slides = [
    {
      src: image_01,
      title: 'Slide Title One',
      description: 'Slide Description One\n\nthis is new line',
    },
    {
      src: image_02,
      title: 'Slide Title Two',
      description: 'Slide Description Two',
    },
    {
      src: image_03,
      title: 'Slide Title',
      description: 'Slide Description',
    },
    {
      src: image_04,
      title: 'Slide Title',
      description: 'Slide Description',
    },
    {
      src: image_05,
      title: 'Slide Title',
      description: 'Slide Description',
    },  
    {
      src: image_06,
      title: 'Slide Title',
      description: 'Slide Description',
    },  
    {
      src: image_07,
      title: 'Slide Title',
      description: 'Slide Description',
    },  
    {
      src: image_08,
      title: 'Slide Title',
      description: 'Slide Description',
    },    
  ]

import { map } from 'leaflet';
import Carousel from 'react-bootstrap/Carousel';

export default function SliderVehiculos() {


const items = [
    {id:1, nombre:'Buses', imagen:require('./../img/vehiculos/01.jpg')},    
    {id:2, nombre:'Autos', imagen:require('./../img/vehiculos/02.jpg')},    
    {id:3, nombre:'Vans', imagen:require('./../img/vehiculos/03.jpg')},    
    {id:4, nombre:'Autos', imagen:require('./../img/vehiculos/04.jpg')},    
    {id:5, nombre:'Autos', imagen:require('./../img/vehiculos/05.jpg')}
];

    return (
        <>


            <Carousel interval={3500}>

                    {items.map((item)=>{
                        return(
                            <Carousel.Item key={item.id}  >
                            <img className='d-block w-100' src={item.imagen} alt='First slide' />
                            <Carousel.Caption>
                            <h3 className='bg-dark bg-opacity-50'>{item.nombre}</h3>
                            </Carousel.Caption>
                            </Carousel.Item>
                            )
                    })}
                </Carousel>
        </>
    )
}

import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Acuario() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'acuario.jpg', titulo: 'ACUARIO Y MUSEO DEL MAR RODADERO', contenido: 'El Acuario y museo del mar del Rodadero es un acuario público y un museo marítimo situado en la ensenada Inca Inca frente a la playa El Rodadero. Fue inaugurado en 1965 por el capitán Francisco Ospina Navia. El acuario es parte de la Red Nacional de Museos de Colombia. Accesible principalmente en lancha, el acuario cuenta con 13 piscinas con conexión directa con el mar Caribe, y 15 acuarios de vidrio que contienen más de 805 animales que incluyen tiburones, tortugas marinas, delfines, leones marinos, crustáceos, peces y aves marinas, el 98% de los cuales son nativos de la zona.' },
        { id: 2, folder: 'tours', imagen: 'playablanca.jpg', titulo: 'PLAYA BLANCA', contenido: 'Hay que decir que el nombre de Playa Blanca es muy apropiado. Hasta este punto del norte de la bahía de Santa Marta, Colombia, se llega desde las playas de El Rodadero luego de un viaje en lancha. Una vez allí, los visitantes pueden practicar deportes acuáticos y encuentran varias opciones de comida.' }
    ];


    return (
        <div >

            <FormularioTour titulo='ACUARIO Y PLAYA BLANCA'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='9:00am - 5:00 pm' titulo='Itinerario' >
                <div>
                    <p>recogida en vehículo climatizado en el hotel hasta el muelle de embarque ida y regreso(excepto hoteles del rodadero), paseo en lancha hasta playa Blanca, entrada al acuario, servicio de guía, seguro en impuestos (plan sin almuerzo).</p>                    
                    
                </div>
            </FormItinerario>
        </div>
    )

}
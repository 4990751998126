import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Buritaca() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'quebradav.jpg', titulo: 'QUEBRADA VALENCIA', contenido: 'Bañarse en pozos con agua refrescante que fluye desde la Sierra Nevada de Santa Marta es posible en la quebrada Valencia, en el oeste del Parque Nacional Natural Tayrona, donde se encuentran varias caídas naturales de agua. Para llegar a estas cascadas se recorre durante treinta minutos un sendero por tierra que pasa por la quebrada Valencia.' },
        { id: 2, folder: 'tours', imagen: 'buritaca.jpg', titulo: 'CABAÑAS DE BURITACA', contenido: 'Bocas de Buritaca es un pequeño poblado localizado a 48 km de Santa Marta. Allí es posible zambullirse en la frescura del río Buritaca o en las aguas tranquilas del mar Caribe, en las que el primero desemboca más adelante. Este lugar es muy visitado y se pueden encontrar servicios de alojamiento y alimentación.' },
        
    ];


    return (
        <div >

            <FormularioTour titulo='QUEBRADA VALENCIA - CABAÑAS DE BURITACA'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='salida 8:00 am -4:00 pm' titulo='Itinerario' >
                <div>
                    <p>Salida en vehículo climatizado hasta la entrada de quebrada valencia, donde iniciaremos una caminata de 20 min. aproximadamente por una hermosa vegetación hasta llegar a los pozos de agua de la sierra donde podran tomar baño para luego continuar con el tour hasta las playas de buritaca donde desemboca el rio en el mar, donde podran seguir disfrutando del paseo en el mar</p>                    
                    <p><strong>Recomendaciones: </strong>ropa y tennis para caminar, vestido de baño</p>
                    <strong>Incluye</strong>
                    <ul>
                        <li>transporte en vehículo climatizado ida y regreso</li>
                        <li>Guía</li>
                        <li>Entradas</li>
                        <li>Seguros e impuestos</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}
import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Tayrona() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'canaveral3.jpg', titulo: 'CAÑAVERAL', contenido: 'La playa de Cañaveral, situada en el Parque Nacional Natural Tayrona, se caracteriza por su abundante vegetación, especialmente de cocoteros. Cuenta con una zona especial para acampar, un restaurante y dispone de un área de alojamiento cuyas unidades habitacionales denominadas ‘ecohabs’ emulan la típica vivienda tayrona y desarrollan un sistema funcional de ventilación.' },
        { id: 2, folder: 'tours', imagen: 'arrecife2.jpg', titulo: 'ARRECIFE', contenido: 'En cualquier caso, Playa Arrecifes es, y lo más probable es que seguirá siendo, el área más recorrida y una de las más divertidas del Tayrona. La razón para sostener esta afirmación abarca desde su belleza hasta los lugares que puedes recorrer desde Arrecifes: La Piscina y Cabo san Juan del Guía se perfilan como indeclinables lugares a conocer. Y a decir verdad no se necesita de mucho tiempo para encadenar la visita en estos tres preciosos sitios, y qué más si se hace en caminatas y cabalgatas a través de seguros senderos abiertos en medio del bosque húmedo tropical.' },
        { id: 3, folder: 'tours', imagen: 'piscina.jpg', titulo: 'PISCINA', contenido: 'Es una de las pocas playas de Tayrona donde puede darse un baño relajante. Toma su nombre de la barrera de rocas que rodea la costa creando un estanque natural de aguas tranquilas, se dice que los antiguos indígenas colocaron esa barrera de rocas. Es una pequeña playa de espectacular belleza. No hay zona de acampada ni hamacas. Se encuentra entre Cabo San Juan y Arrecife.' },
        { id: 4, folder: 'tours', imagen: 'sanjuan2.jpg', titulo: 'CABO SAN JUAN', contenido: 'A Cabo San Juan de Guía, en el Parque Nacional Natural Tayrona, se puede llegar desde el sector de Arrecifes por un camino entre la selva o siguiendo una ruta a lo largo de la playa sobre rocas volcánicas enormes. Al final se encuentra un lugar perfecto para acampar, a la orilla del Caribe, donde viajeros de todo el mundo se juntan para celebrar bajo el sol.' }
    ];


    return (
        <div >

            <FormularioTour titulo='CAÑAVERAL - ARRECIFES - PISCINA - CABO SAN JUAN'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='salida 8:00 am - regreso a santa marta desde cabo san juan: 3:00 o 3:30 pm' titulo='Itinerario' >
                <div>
                    <p>Salida de santa marta en vehículo climatizado (aproximadamente 1 hora) hasta la entrada del parque, donde iniciaremos la caminata por el bosque tropical hasta llegar a las playas de arrecifes (aproximadamente 45 min.) donde podrá refrescarse para seguir la caminata hasta la piscina natural y el cabo san juan (aproximadamente 45 min. más) donde podrá disfrutar de las playas y podrá descansar para iniciar el regreso, a las 3:00 o 3:30 pm</p>
                    <p><strong>Recomendaciones: </strong>buen estado físico, ropa adecuada y tennis para caminar.</p>
                    <strong>Incluye</strong>
                   <ul>
                        <li>transporte en vehículo climatizado ida y regreso</li>
                        <li>Guía</li>
                        <li>Entradas</li>
                        <li>Seguros e impuestos</li>
                        </ul>
                </div>
            </FormItinerario>
        </div>
    )

}
import React from 'react';
export default function FormItinerario( props:formItinerarioProps) {

    return (
        <div className='w-100 mx-auto'>
            <table className="table table-bordered mt-5 bg-white">
                <tbody>
                    <tr>
                        <td width="30%"  ><strong>Horario</strong></td>
                        <td width="70%" ><span >{props.horario}</span></td>
                    </tr>
                    <tr>
                        <td><strong>{props.titulo}</strong></td>
                        <td>
                            {props.children}
                        </td>
                    </tr>
                    <tr>
                        <td><span><strong><span >Precio por persona</span></strong></span></td>
                        <td><span >Contáctenos</span></td>
                    </tr>
                    <tr>
                        <td><span ><strong><span >Teléfono</span></strong></span></td>
                        <td><span >+57 3117045300   +57 3114224954</span></td>
                    </tr>
                    <tr style={{ textAlign: 'center' }} >
                        <td colSpan={2} ><a href="tel:+3114224954">
                            <img src={require('./../img/telefono.png')} style={{marginRight:'20px'}} width="50" height="50" /></a>
                            <a href="https://api.whatsapp.com/send?phone=573114224954&amp;text=Hola%2C%20Estoy%20en%20el%20plan:1%Guajira">
                                <img src={require('./../img/whatsapp.png')}   style={{marginLeft:'20px'}}  width="50" height="50" />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

}

interface formItinerarioProps{
    horario:string;
    titulo:string;
    children:React.ReactElement;
}
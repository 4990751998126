import Servicios from '../modules/Servicios';
import NosotrosContent from '../modules/NosotrosContenido';
import FormularioTour from '../modules/FormularioTour';
export default function Transporte() {

    return (
        <div>

            <FormularioTour titulo='TRANSPORTE TERRESTRE:'>
                <div>
                    <p>Una de nuestras fortalezas como empresa es el de brindar un servicio de viajes de negocios, escursiones turisticas. Todos nuestros vehículos cuentan con sistema de GPS las 24 horas los 7 días de la semana. </p>
                    <p><b>Servicios:</b></p>
                    <ul>
                        <li>Transporte a cualquier parte del país </li>
                    </ul>
                    <ul>
                        <li>Trabajamos en común acuerdo con el aeropuerto internacional Simón Bolívar, si usted necesita un conductor de confianza puede comunicarse con nosotros para que su viaje sea más tranquilo y seguro. </li>
                    </ul>
                    <ul>
                        <li>Contamos con servicio de conductor elegido, para que pueda disfrutar de su rumba en completa tranquilidad y sin tener que manejar. </li>
                    </ul>
                    <ul>
                        <li>Viajes de placer o negocios </li>
                    </ul>
                    <ul>
                        <li>Transporte aeropuerto-hotel </li>
                    </ul>
                    <ul>
                        <li>Santa Marta-Cartagena </li>
                    </ul>
                    <ul>
                        <li>Santa Marta-Maicao </li>
                    </ul>
                    <ul>
                        <li>Santa Marta-Mompox </li>
                    </ul>
                    <ul>
                        <li>Santa marta-Barranquilla </li>
                    </ul>
                    <p><strong>*Entre otros destinos </strong></p>
                </div>
            </FormularioTour>

            <Servicios></Servicios>
        </div>
    )

}
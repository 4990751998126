

export default function CardHorizontal(props: cardHorizontalProps) {
    return (

        <div className="w-100">
            <div className="card col-12 mb-2" >
                <div className='row'>
                    <div className='col-12 col-sm-4'>
                        <img className="card-img-top" src={require("./../img/" + props.folder + "/" + props.imagen)} alt="Card image cap" />
                    </div>
                    <div className='col-12 col-sm-8 p-4 ' style={{textAlign:'justify'}}  >
                        <h5>{props.titulo}</h5>
                        <p style={{ textAlign: 'justify' }} className="card-text">{props.contenido}</p>
                    </div>
                </div>

            </div>
        </div>

    )
}



interface cardHorizontalProps {
    titulo: string;
    folder: string;
    imagen: string;
    contenido: string;
}
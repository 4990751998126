import Logo from './../img/logosmallblanco.png'
import { Link, NavLink } from 'react-router-dom';
import { Background } from 'react-parallax';

export default function Menu() {

  const claseActiva = "active";

  return (
    <div className='row'>



      <nav className="navbar navbar-expand-lg navbar-light static-top" style={{ marginTop: '10px', backgroundColor: '#c1d3e9' }} >
        <div className="container">


          <NavLink className="navbar-brand" to="/" activeClassName={claseActiva} >
            <img src={Logo} alt="..." height="60" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>


          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto" style={{ fontSize: '18px', fontWeight: '500' }} >

              <li className="nav-item">
                <NavLink className="nav-link" to="/" activeClassName={claseActiva}   >Inicio</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link " to="/nosotros" activeClassName={claseActiva} >Nosotros</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link " to="/casa-sm" activeClassName={claseActiva} >Casa Santa Maria</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link " to="/internacional" activeClassName={claseActiva} >Internacional</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link " to="/turismo" activeClassName={claseActiva} >Turismo</NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link " to="/contactenos" activeClassName={claseActiva} >Contactenos</NavLink>
              </li>





              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="/nosotros" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Tours
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><NavLink className="dropdown-item" to="/citytour" activeClassName={claseActiva} >PLAN 1: CITY TOUR</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/neguanje" activeClassName={claseActiva} >PLAN 2: PLAYA CRISTAL - NEGUAJE - 7 OLAS</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>                  
                  <li><NavLink className="dropdown-item" to="/gairaca" activeClassName={claseActiva} >PLAN 3: TOUR PLAYA GAIRACA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/dondiego" activeClassName={claseActiva} >PLAN 4: TOUR DON DIEGO - PALOMINO</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>                  
                  <li><NavLink className="dropdown-item" to="/tayrona" activeClassName={claseActiva} >PLAN 5: TOUR TAYRONA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>                  
                  <li><NavLink className="dropdown-item" to="/buritaca" activeClassName={claseActiva} >PLAN 6: QUEBRADA VALENCIA - BURITACA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>                  
                  <li><NavLink className="dropdown-item" to="/minca" activeClassName={claseActiva} >PLAN 7: MINCA-FINCA DE CAFÉ LA VICTORIA-POZO AZUL</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/santuario" activeClassName={claseActiva} >PLAN 8: SANTUARIO DE LOS FLAMENCOS</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/cartagena" activeClassName={claseActiva} >PLAN 9: CARTAGENA DE INDIAS</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/guajira" activeClassName={claseActiva} >PLAN 10: GUAJIRA EXÓTICA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/termales" activeClassName={claseActiva} >PLAN 11: TERMALES DE CORDOBA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/acuario" activeClassName={claseActiva} >PLAN 12: ACUARIO Y PLAYA BLANCA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/bahia-concha" activeClassName={claseActiva} >PLAN 13: BAHIA CONCHA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/mompox" activeClassName={claseActiva} >PLAN 14: TOUR MOMPOX</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/barranquilla" activeClassName={claseActiva} >PLAN 15: TOUR BARRANQUILLA</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                 
                  <li><NavLink className="dropdown-item" to="/otros-destinos" activeClassName={claseActiva} >OTROS DESTINOS</NavLink></li>
                </ul>
              </li>



              <li className="nav-item dropdown">
                <NavLink className="nav-link dropdown-toggle" to="/servicios" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Servicios
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><NavLink className="dropdown-item" to="/vehiculos" activeClassName={claseActiva} >Vehiculos</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/transporte" activeClassName={claseActiva} >Transporte</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" to="/convenios" activeClassName={claseActiva} >Convenios</NavLink></li>
                </ul>
              </li>




            </ul>
          </div>


        </div>
      </nav>
    </div>


  )

}
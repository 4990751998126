import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Minca() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'minca.jpg', titulo: 'MINCA', contenido: 'Minca es un pequeño pueblo cafetero enclavado en las montañas de la Sierra Nevada de Santa Marta, de unos 600 habitantes aproximadamente y se encuentra a unos 660 mts sobre el nivel del mar. Se encuentra a unos 14 km desde Santa Marta atravesando la montaña y se llega por carretera. En Minca se puede visitar fincas cafeteras, tomar deliciosos baños en los ríos que bajan desde las montañas, ver la ciudad de santa marta desde los miradores turísticos, puedes hacer caminata, hacer cabalgatas, hacer ciclomontañismo, hacer tubing, etc ' },
        { id: 2, folder: 'tours', imagen: 'pozoazul.jpg', titulo: 'POZO AZUL', contenido: 'Paisajes, aguas cristalinas y una gran variedad de flora y fauna hacen parte de este bello lugar. Una formación natural enclavada en estas montañas de la Sierra Nevada de Santa Marta, que hace las veces de una piscina de agua fresca y helada. Pozo Azul toma su nombre de la refracción del espectro solar que en tonos celestes se observan en la cascada, el destello del agua con los haces de luz penetran a través de las ramas de inmensos árboles.' },
        { id: 3, folder: 'tours', imagen: 'victoria.jpg', titulo: 'FINCA CAFETERA LA VICTORIA', contenido: 'La Victoria es una de las haciendas cafeteras más extensas y antiguas del norte de Colombia. Fue fundada en 1892 por Charles y Alice Bowden, una pareja de empresarios británicos que llegaron al país para trabajar en el desarrollo de los ferrocarriles. Llamada así en honor a la Reina Victoria de Inglaterra, la finca se extendía originalmente sobre 1223 hectáreas en un territorio que fue habitado, siglos atrás, por comunidades indígenas Tayrona – a través de la finca todavía pueden encontrarse terrazas y caminos de piedra precolombinos. Ya para el año 1921, la Compañía Cafetera La Victoria se posicionaba como un productor líder en la región con cerca de 200.000 kg de café anuales. Sin embargo, los cultivos de café sólo ocupaban 217 del total de sus hectáreas, mientras que el resto fueron conservadas como una reserva natural que se mantiene prácticamente intacta hasta el día de hoy. Los densos bosques y los abundantes arroyos de La Victoria albergan una vegetación exuberante habitada por numerosas especies de animales tropicales, particularmente de aves exóticas – se han encontrado más de 210 especies, 7 de las cuales han sido catalogadas como endémicas.' },
       ];


    return (
        <div >

            <FormularioTour titulo='Minca-Finca de Café la Victoria- Pozo Azul'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='salida 9:00 am -4:00 pm' titulo='Itinerario' >
                <div>
                    <p>
                    recogida en el hotel. llegando a la finca la victoria donde podrá ver todo el proceso de elaboración del café, la historia y el funcionamiento de la finca cafetera. Despues de ver todo el proceso podrá disfrutar de un delicioso café o una cerveza artesanal. Luego seguiremos hasta pozo azul o las cascadas de marinka donde podrá tomar un delicioso baño, Despues podrá disfrutar de un merecido almuerzo si usted lo desea y por ultimo seguiremos al mirador donde podrá observar la bella naturaleza y la vista de santa marta
                    </p>
                    <p><strong>Recomendaciones: </strong>zapatos adecuados para caminar, repelente, vestido de baño</p>
                    <strong>Incluye</strong>
                    <ul>
                        <li>transporte climatizado en vehículo 4X4 ida y regreso</li>
                        <li>Entradas</li>
                        <li>Seguros e impuestos</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}

import Vehiculos from './../img/servicios/vehiculos.png'
import Trasnsporte from './../img/servicios/transporte.png'
import Convenios from './../img/servicios/convenios.png'


export default function Servicios() {
    return (
        <div className='row'>
            <section className="container-fluid mx-auto text-center pt-2">


                <div>
                    <h3 className='p-3 fs-3 border-top border-3 '>
                        Nuestros Servicios
                    </h3>
                </div>


                <div className='row w-75 mx-auto'>

                    <div className='col-lg-4 col-md-12 col-sm-12'>
                        <img className='d-block w-100' src={Vehiculos} alt='Mensajero' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Vehiculos</h5>
                        <p className='px-4 text-center'>Contamos con vehiculos para facilitar su desplazamiento.</p>
                    </div>


                    <div className='col-lg-4 col-md-12 col-sm-12'>
                        <img className='d-block w-100' src={Trasnsporte} alt='24 Horas' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Transporte terrestre</h5>
                        <p className='px-4 text-center'>Prestamos el servicio de transporte terrestre, para facilitar su traslado.</p>
                    </div>

                    <div className='col-lg-4 col-md-12 col-sm-12'>
                        <img className='d-block w-100' src={Convenios} alt='24 Horas' />
                        <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Convenios</h5>
                        <p className='px-4 text-center'>Contamos con convenios con una amplia gama de hoteles y restaurantes de la región.</p>
                    </div>

                </div>
            </section>
        </div>

    )
}
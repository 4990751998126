import Servicios from '../modules/Servicios';
import NosotrosContent from '../modules/NosotrosContenido';
export default function Nosotros() {

    return (
        <div>
            <NosotrosContent></NosotrosContent>
            <Servicios></Servicios>
        </div>
    )

}
import React from 'react';
import SliderTours from "../modules/SliderTours";
import { CoordenadaDTO } from "../mapaLeaflet/Coordenada.model";
import ParallaxUno from "../modules/ParallaxUno";
import Servicios from "../modules/Servicios";
import DescripcionSitio from "../modules/DescripcionSitio";
import SliderVehiculos from '../modules/SliderVehiculos';

export default function Home() {


    //11.2379455, -74.207022
    const coordenadas: CoordenadaDTO[] = [{ lat: 11.2379455, lng: -74.207022 }];

    return (

        <div>



            <div className="me-12 d-none d-lg-block"   >
                <ParallaxUno></ParallaxUno>
            </div>

            <div className="me-12  d-lg-none"   >
                <DescripcionSitio></DescripcionSitio>
            </div>


            <div className='row'>
                <div className="col-12 col-sm-6" >
                    <h3 className='mt-4 text-center'>Nuestros Tours</h3>
                    <SliderTours />
                </div>
                <div className="col-12 col-sm-6" >
                    <h3 className='mt-4 text-center'>Nuestros Vehiculos</h3>
                    <SliderVehiculos />
                </div>
            </div>

            <Servicios></Servicios>

        </div>

    )

}
import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Guajira() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'cdvela.jpg', titulo: 'CABO DE LA VELA', contenido: 'Para descansar en unas de las playas más tranquilas de Colombia. En este sitio, al que llegó en 1499 el capitán Alonso de Ojeda, los viajeros encuentran posadas turísticas –varias hechas con bahareque y yotojoro (el corazón del cactus)– y pueden practicar deportes como kitesurf y windsurf.' },
        { id: 2, folder: 'tours', imagen: 'manaure.jpg', titulo: 'SALINAS DE MANAURE', contenido: 'Las palas comienzan a romper las montañas de sal antes de que se levante el sol. Las personas que recogen este mineral en las charcas de las salinas de Manaure, adonde se llega luego de viajar por tierra 63 kilómetros hacia el nororiente desde Riohacha, madrugan para evitar el calor. El paisaje en esta zona, que mide 4.200 hectáreas, es extraño: cerros de sal que se reflejan en pozos de agua' },
        { id: 3, folder: 'tours', imagen: 'riohacha.jpg', titulo: 'RIOHACHA', contenido: 'Riohacha, que se encuentra frente al Mar Caribe y al lado del desierto, es la puerta de entrada para conocer la península de La Guajira, el departamento más septentrional de Colombia. Esta ciudad, en la que desemboca el río Ranchería, fue fundada el 6 de agosto de 1545. Quienes la visitan encuentran el legado de los indígenas Wayúu, que se manifiesta en mantas y mochilas coloridas, entre otras cosas.' },
    ];


    return (
        <div >

            <FormularioTour titulo='Guajira exótica: Cabo de la Vela - salinas de Manaure - Riohacha'>
            <div>
            <p><span >En este tour podrás conocer las diferentes maravillas que el departamento de la Guajira tiene para ofrecer, visitaran los siguientes lugares: </span></p>
                <ul>
                    <li><span >Cabo de la Vela</span></li>
                    <li><span >Pilón de Azúcar</span></li>
                    <li><span >Puesta de Sol</span></li>
                    <li><span >Salinas de Manaure</span></li>
                    <li><span >Uribía</span></li>
                    <li><span >Riohacha</span></li>
                </ul>
            </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='salida 4:30 am -4:00 pm (día siguiente)' titulo='Itinerario' >
                <div>
                    <p>Primer dia: recogida 4:30 am en vehículo climatizado saliendo vía a Riohacha, desayuno, siguiendo, Uribia, cavo de la vela, almuerzo, para luego seguir con el recorrido, Rancheria, playa pilon de azucar, ojo del agua, mirador del faro, puesta del sol, despues de la puesta del sol seguiremos hacia la rancheria para la cena</p>
                    <p >Segundo dia: salinas de Manaure y Riohacha, almuerzo, regresando a santa marta 4:00 pm </p>
                    <p >Recomendaciones: zapatos cómodos, protector solar, toalla, linterna, útiles de aseo personal</p>
                </div>
            </FormItinerario>
        </div>
    )

}
export default function Convenios() {

    return (
        <div className="mb-2">
            <div className="col-12 mb2">            
            <h2 className='text-center mt-2 mb-2'><span><strong>Convenios</strong></span></h2>
                <span>trabajamos y tenemos convenios con estos hoteles, los cuales nos han depositado toda su confianza y seguridad en nuestro transporte, y si usted desea reservar alojamiento, solo ingrese al link o al celular y sera atendido directamente por ellos, igual si desea apartamento, llamar a los contactos que aparecen de apartamentos</span>
            </div>

            <div className="text-center mt-2">
                <img src={require("./../img/convenios/01-paleteria.jpg")} alt="" />
                <a href="http://www.mulatahostel-santamarta.com/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/01-mulata.jpg")} alt="" />
                </a>
                <a href="http://www.casachapolin.com/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/02-chapolin.jpg")} alt="" />
                </a>
                <a href="http://mamancana.co/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/03-mamancana.jpg")} alt="" />
                </a>
                <a href="http://reservaone.love/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/03-onelove.jpg")} alt="" />
                </a>
                <img src={require("./../img/convenios/04-casadelrio.jpg")} alt="" />
                <a href="http://gitanadelmar.com.co/" target="_blank" rel="noopener noreferrer">
                    <img src="./../img/convenios/04-gitana.jpg" alt="" />
                </a>
                <img src={require("./../img/convenios/04-alcatraces.jpg")} alt="" />
                <a href="http://www.hotelrestaurantecasadantonio.com/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/05-casaantonio.jpg")} alt="" />
                </a>
                <a href="http://lasierrahostel.com/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/06-lasierra.jpg")} />
                </a>
                <a href="https://www.facebook.com/elnisperocasadehuespedes/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/09-nispero.jpg")} />
                </a>
                <a href="http://casasemillapalomino.com/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/07-semilla.jpg")} />
                </a>
                <a>
                    <img src={require("./../img/convenios/07-anugwe.jpg")} />
                </a>
                <a href="https://www.facebook.com/EivissaSantaMarta/" target="_blank" rel="noopener noreferrer">
                    <img src={require("./../img/convenios/07-eivissa.jpg")} /> </a>
                <a>
                    <img src={require("./../img/convenios/08-luxury.jpg")} />
                </a>
            </div>

        </div>
    )
}
import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function PlayaCristal() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'cristal2.jpg', titulo: 'PLAYA CRISTAL', contenido: 'Playa Cristal o Playa del muerto es una hermosa bahía ubicada a solo 15 minutos en lancha desde Neguanje, con aguas cristalinas, arenas blancas y hermosos arrecifes coralinos ideales para la práctica del careteo o snorkeling. Este tour es perfecto para quienes disfrutan de la belleza natural del mar y de pasar un tiempo de relax en la playa.' },
        { id: 2, folder: 'tours', imagen: 'neguanje3.jpg', titulo: 'BAHIA NEGUAJE', contenido: 'Bahía Neguanje está en el Parque Nacional Natural Tayrona y ofrece a los turistas una de las playas con arena más suave de la región: playa Cristal. En este lugar basta una careta para practicar el buceo de superficie y ver la vida bajo el mar. Este punto se encuentra a unos 12 kilómetros al norte de Santa Marta.' },
        { id: 3, folder: 'tours', imagen: 'sieteolas.jpg', titulo: 'SIETE OLAS', contenido: 'En el recorrido hacia Neguanje y playa Cristal en el Parque Tayrona, nos encontramos con un mirador desde donde podemos apreciar la Playa Siete olas, también conocida como Playa Brava. La sensación cuando ves esta hermosa playa desde la altura del mirador es indescriptible, la inmensidad y majestuosidad del mar entrando y saliendo de la playa con su fuerte e imparable oleaje te hace sentir lo pequeño que eres en este infinito universo.' },
    ];


    return (
        <div >

            <FormularioTour titulo='PLAYA CRISTAL - NEGUAJE - 7 OLAS'>
                <div>

                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='9:00 am o 2:00 pm' titulo='Incluye' >
                <div>
                    <ul>
                        <li>Transporte terrestre climatizado hasta la bahia de neguanje y lancha hasta playa cristal ida y regreso</li>
                        <li>Entradas al parque</li>
                        <li>Seguros e impuesto</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Mompox() {



    return (
        <div >

            <FormularioTour titulo='TOUR MOMPOX'>
                <div>
                </div>
            </FormularioTour>


            <div className="w-100">
                <div className="card col-12 mb-2" >
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img className="card-img-top" src={require("./../img/tours/mompox5.jpg")} alt="Card image cap" />
                        </div>
                        <div className='col-12 col-sm-8 p-4 ' style={{textAlign:'justify'}} >
                            <h5>Santa Cruz de Mompox</h5>
                            <p>Santa Cruz de Mompox, fundada en 1530 por los españoles, en una gran isla formada en el río Magdalena en su recorrido hacia el mar. Se constituyó como puerto importante para el transporte de mercancías y por él circularon personajes que, interesados en las riquezas de las nuevas tierras, se aventuraron a explorarlas. Desde su fundación preserva rasgos arquitectónicos surgidos de la mezcla española e indígena de sus constructores. El trabajo del hierro forjado que adorna puertas, rejas y ventanas de las Calles de la Albarrada, Real del Medio y de Atrás, se refleja también en sus iglesias como Santa Bárbara, San Agustín, San Juan de Dios y la Concepción.</p>
                            <p>Mompox está situada en el departamento de Bolívar y al sur del mismo. Actualmente es municipio y se encuentra dentro de la Isla Margarita o de Mompox, esta isla se halla en la situación geográfica llamada la depresión momposina, bañada por las aguas del río Magdalena, con los brazos de Loba, Mompox y Chicagua.</p>
                        </div>
                    </div>

                </div>
            </div>

            <table className="table table-bordered mt-5 bg-white">
                <tbody>
                    <tr>
                        <td width="25%"><strong>Tipos de Plan</strong></td>
                        <td >
                            <p ><strong>Tour privado:</strong> transporte en vehículo climatizado ida y regreso Recorrido: Siete iglesias, calle del medio, convento de san carlos, plaza santa barbara, al medio día podrá disfrutar de la gastronomia momposina y de un delicioso almuerzo, luego seguiremos con el recorrido de los talleres de orfebrería y filigrana, fabrica de queso y fabrica de vino artesanal.</p>
                            <p ><strong>Tour opcional (de 4:30 a 6:30 pm):</strong> paseo en planchon por el brazo del rio magdalena donde podrá disfrutar de una deliciosa cerveza y podra avistar diferentes clases de aves y fauna silvestre </p>
                            <p ><strong>Tour opcional (de 3:00 a 6:00 pm):</strong> cienaga de pijiño donde podra ver babillas, tortugas, iguanas, garzas. Y luego de navegar 25 minutos llegaremos a pijiño del carmen donde podra darse un chapuzón despues de un caluroso dia. </p>
                            <p >Recomendaciones: gorra, protector solar, repelente, vestido de baño.</p>
                        </td>
                    </tr>
                    <tr>
                        <td><span><strong><span >Precio por persona</span></strong></span></td>
                        <td><span >Contáctenos</span></td>
                    </tr>
                    <tr>
                        <td><span ><strong><span >Teléfono</span></strong></span></td>
                        <td><span >+57 3117045300   +57 3114224954</span></td>
                    </tr>
                    <tr style={{ textAlign: 'center' }} >
                        <td colSpan={2} ><a href="tel:+3114224954">
                            <img src={require('./../img/telefono.png')} style={{marginRight:'20px'}} width="50" height="50" /></a>
                            <a href="https://api.whatsapp.com/send?phone=573114224954&amp;text=Hola%2C%20Estoy%20en%20el%20plan:1%Guajira">
                                <img src={require('./../img/whatsapp.png')}   style={{marginLeft:'20px'}}  width="50" height="50" />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

}

import Servicios from '../modules/Servicios';
import SliderVehiculos from '../modules/SliderVehiculos';
export default function NuestrosVehiculos() {

    return (
        <div>
            <SliderVehiculos></SliderVehiculos>
            <Servicios></Servicios>
        </div>
    )

}
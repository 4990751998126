import Servicios from '../modules/Servicios';
import FormularioContacto from '../modules/FormularioContacto';


export default function Contactenos() {

    
    return (
        <div className='bg-white w-100'>
            <FormularioContacto></FormularioContacto>
            <Servicios></Servicios>
        </div>


    )

}
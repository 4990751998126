
import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Santuario() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'flamencos.jpg', titulo: 'SANTUARIO DE FAUNA Y FLORA LOS FLAMENCOS', contenido: 'Uno de los espectáculos que podrá disfrutar el visitante es la quietud de los cielos azules y despejados de la península de La Guajira, interrumpida por arreboles de un rosado intenso que se disuelven en espirales sobre las lagunas del Santuario. Se trata de bandadas de flamencos, aves esbeltas de plumaje rosa que dan el nombre a esta área protegida. Los gráciles flamencos y el majestuoso colorido de su plumaje son el principal atractivo del Santuario, que a pesar de su reducida extensión, es considerado una de las áreas protegidas más ricas en variedad de aves acuáticas residentes y migratorias. En este tour usted podrá disfrutar de las delicias gastronómicas de la región, tales como arroz de camarón y langostas procedentes de esta zona.' },
        ];


    return (
        <div >

            <FormularioTour titulo='SANTUARIO DE FAUNA Y FLORA LOS FLAMENCOS'>
                <div >
            </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='salida 8:00 am -5:00 pm' titulo='Itinerario' >
                <div>
                    <p>salida en vehículo climatizado desde el hotel, tomando la vía a la guajira hasta llegar al santuario de los flamencos (aproximadamente 2 horas y 30 minutos) al llegar tomaremos una lancha artesanal guiada por indígenas wayú de la región hasta llegar donde se encuentran estas bellas aves esbeltas de plumaje rosa que dan el nombre a esta área protegida y también podrá observar a los famosos patos cuchara, después podrá disfrutar de un delicioso almuerzo si usted lo desea con las especialidades de la región tales como, arroz de camarón y langostas procedentes de esta zona. Luego seguiremos a Riohacha para que usted pueda disfrutar de la compra de artesanías y mochilas wayú</p>                    
                    <p><strong>Recomendaciones: </strong>zapatos cómodos, repelente, protector solar, gorra</p>
                    <strong>Incluye</strong>
                    <ul>
                        <li>transporte terrestre en vehículo climatizado ida y regreso</li>
                        <li>Lancha artesanal</li>
                        <li>Entradas</li>
                        <li>Seguros e impuestos</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}
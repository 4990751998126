export default function Footer() {

  return (
    <div className="row mb-4">

      <footer className="text-center text-lg-start text-muted" style={{ backgroundColor: '#c1d3e9' }}>

        <section className="d-flex justify-content-center  p-4 border-bottom">


          <div className="me-5 d-none d-lg-block">
            <span>Siguenos en nuestras redes sociales:</span>
          </div>

          <div>
            <a href="https://www.facebook.com/profile.php?id=100063577719906/" target="_blank" className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/innova_express" target="_blank" className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
          </div>

        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">

              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3"></i>Alvaro Santamaria Viajes y Turismo
                </h6>
                <p>
                  Es una agencia de viajes con más de 25 años de experiencia en el sector transportador y turístico. Somos una empresa ampliamente reconocida y consolidada a nivel local y regional.
                </p>
              </div>



              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4" style={{ textAlign: 'center' }} >
                <h6 className="text-uppercase fw-bold mb-4">
                  Servicios
                </h6>
                <p>
                  <a href="#!" className="text-reset">Vehiculos</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Transporte terrestre</a>
                </p>
                <p>
                  <a href="#!" className="text-reset">Convenios</a>
                </p>
              </div>





              <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4" style={{ textAlign: 'center' }} >

                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                <p><i className="fas fa-envelope me-3"></i>contacto@santamariaviajesyturismo.com</p>
                <p><i className="fas fa-phone me-3"></i>+57 311 4224954</p>
                <p><i className="fas fa-phone me-3"></i>+57 311 7045300</p>
              </div>

            </div>

          </div>
        </section>



        <div className="text-center pt-4 pb-4 row" style={{ backgroundColor: '#015e9c', color: '#FFF' }}>
          <p>
            © 2022 Copyright: <a style={{ textDecoration: 'none' }} className="text-reset fw-bold" href="https://multinet.com.co/">  Multinet Soluciones Digitales</a>
          </p>
        </div>
      </footer>
    </div>


  )


}
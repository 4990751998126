import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';
import Gallery from '../modules/Gallery';
import { slides } from '../modules/imagesSlidesBarranquilla';

export default function CityTour() {


  

    return (
        <div className='container'>
             <div className='mt-3'>
                <h3>Tour Barranquilla</h3>
                <p>
                ¡Descubre Barranquilla, la puerta de oro de Colombia! Conocida por su vibrante carnaval, esta ciudad caribeña te ofrece playas soleadas, deliciosa gastronomía, y una rica historia cultural. Pasea por el Malecón del Río Magdalena y sumérgete en la alegría contagiosa de sus festividades. Barranquilla te espera con los brazos abiertos y una experiencia inolvidable. ¡No te lo pierdas!
                </p>
                <p>
                Podrá apreciar el puerto que se construyó en 1888 donde ingresó toda la gastronomía y aspecto cultural q hoy en día todavia perdura en los sabores locales.
                </p>
               </div>

            <div>

            <FormItinerario horario='9:00am - 5:00 pm' titulo='Itinerario' >
                <div>
                <p>
                9:00 am Salida  hacia malecón del río donde podrá apreciar estatua de Shakira monumento de ventana al mundo aleta del tiburón.
                </p>
                <p>
                1:00 pm parada malecón del río moll de comidas donde podrá Disfrutar si Desea de un delicioso almuerzo.
                </p>
                <p>
                2:30 pm salida a puerto colombia  donde podrá apreciar el monumento ventana delos sueños de 80 metros de altura.
                </p>
                    <strong>Incluye</strong>
                    <ul>
                        <li>transporte terrestre en vehículo climatizado ida y regreso</li>
                        <li>Alimentación (opcional)</li>
                    </ul>
                </div>
            </FormItinerario>
            </div>

            
            <Gallery slides={slides} ></Gallery>
        </div>
    )

}
export default function FormularioTour(props:formularioTourProps){

    return(
        <div className="col-12 p-4">            
            <h2 className='text-center mt-2 mb-2'><span><strong>{props.titulo}</strong></span></h2>
            <div className="mt-4">
            {props.children}            
            </div>
            
        </div>
    )
}


interface formularioTourProps{
    titulo:string;
    children:React.ReactElement;
}

import { map } from 'leaflet';
import Carousel from 'react-bootstrap/Carousel';

export default function SliderTours() {


const items = [
    {id:1, nombre:'Plan City Tour', imagen:require('./../img/tours/citytour.jpg')},    
    {id:2, nombre:'Neguanje', imagen:require('./../img/tours/neguanje2.jpg')},    
    {id:3, nombre:'Gayraca', imagen:require('./../img/tours/gayraca2.jpg')},    
    {id:4, nombre:'Tayrona', imagen:require('./../img/tours/tayrona.jpg')}
];

    return (
        <>


            <Carousel interval={3500}>

                    {items.map((item)=>{
                        return(
                            <Carousel.Item key={item.id}  >
                            <img className='d-block w-100' src={item.imagen} alt='First slide' />
                            <Carousel.Caption>
                            <h3 className='bg-dark bg-opacity-50'>{item.nombre}</h3>
                            </Carousel.Caption>
                            </Carousel.Item>
                            )
                    })}
                </Carousel>
        </>
    )
}
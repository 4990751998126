import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Termales() {



    return (
        <div >

            <FormularioTour titulo='TERMALES DE CORDOBA'>
                <div>
                </div>
            </FormularioTour>


            <div className="w-100">
                <div className="card col-12 mb-2" >
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img className="card-img-top" src={require("./../img/tours/termales.jpg")} alt="Card image cap" />
                        </div>
                        <div className='col-12 col-sm-8 p-4 '>
                            <h5>TERMALES DE CORDOBA</h5>
                            <p>Lugar sagrado y retiado de la ciudad, via cienaga, son uno de los poco lugares naturales de la ciudad de Santa Marta que aun conserva su tranquilidad. Relajacion, meditacion y desintoxicación. Estan ubicados a 30 minutos de la ciudad de Santa Marta, es un de los lugares importantes para los indigenas de la Sierra Nevada de Santa Marta. Aqui encontramos las caracteristicas de un lugar campesino, banado por el rio cordoba y que por fenomenos de la naturaleza nacieron fuentes de agua de origen mineral que brotan de la tierra con una temperatura hasta de 40 grados centigrados.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="w-100">
                <div className="card col-12 mb-2" >
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img className="card-img-top" src={require("./../img/tours/termales2.jpg")} alt="Card image cap" />
                        </div>
                        <div className='col-12 col-sm-8 p-4 '>
                            <p>Actividades de relajacion masaje de hidroterapia (arena mineral) relacion cada 5 minutos dentro del las termales para relajar los musculos y tensiones del cuerpo.</p>
                            <p>Mascarilla anti estres, duracion 15 minutos tiempo para respirar y olvidarse de todo por un segundo.</p>
                            <p>No te puedes perder esta oportunidad.</p>
                        </div>
                    </div>

                </div>
            </div>

            <FormItinerario horario='salida 7:00 am (opcional)' titulo='Itinerario' >
                <div>
                    <p>Duración: ida y regreso 3 horas</p>
                    <strong>Incluye</strong>
                    <ul>
                        <li>Entradas</li>
                        <li>Mascarillas</li>
                        <li>Seguros e impuestos</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}

interface objetoCard {
    id: number;
    folder: string;
    imagen: string;
    titulo: string;
    contenido: React.ReactNode;
}
import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Cartagena() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'cartagena2.jpg', titulo: '', contenido: 'La brisa marina y los atardeceres que transforman los colores de las fachadas de casas de más de 400 años hechizan a los visitantes en Cartagena de Indias. Esta ciudad, Patrimonio Histórico de la Humanidad, con tesoros coloniales fue fundada en 1533 por Pedro de Heredia, conserva la arquitectura colonial de sus construcciones y el conjunto de fortificaciones más completo de Suramérica.' },
        { id: 2, folder: 'tours', imagen: 'cartagena5.jpg', titulo: '', contenido: 'Cartagena, Colombia suma a los encantos de su arquitectura colonial, republicana y moderna, los atractivos de una intensa vida nocturna, festivales culturales, paisajes exuberantes, magníficas playas, excelente oferta gastronómica y una importante infraestructura hotelera y turística. Es una ciudad fantástica que guarda los secretos de la historia en sus murallas y balcones.' },
        ];


    return (
        <div >

            <FormularioTour titulo='CARTAGENA DE INDIAS'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='9:00am - 5:00 pm' titulo='Itinerario' >
                <div>
                    <p>transporte en vehículo climatizado, al llegar a cartagena, visitaremos los zapatos viejos, el castillo de san felipe, el cerro de la popa, las murallas y la ciudad vieja, bocagrande y el laguito</p>                    
                    <p><strong>Recomendaciones: </strong>zapatos cómodos, repelente, protector solar, gorra</p>
                    <p>Salidas confirmadas con mínimo 8 pasajeros en microbús y mínimo 4 pasajeros en automóvil</p>
                    <strong>Incluye</strong>
                    <ul>
                        <li>transporte terrestre en vehículo climatizado ida y regreso</li>
                        <li>Alimentación (opcional)</li>
                        <li>Refrigerio</li>
                        <li>Guía</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}
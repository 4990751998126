export default function NosotrosContent() {

    return (
        <div className="w-75 mx-auto pt-5">
            <h2><span ><strong>¿Quienes Somos?</strong></span></h2>
            <p ><strong>Alvaro Santamaria</strong> viajes y turismo, es una agencia de viajes con más de 25 años de experiencia en el sector transportador y turístico. somos una empresa ampliamente reconocida y consolidada a nivel local y regional, nos caracterizamos por brindarles a nuestros clientes diferentes alternativas de viajes, siempre con un acompañamiento constante de nuestro equipo de profesionales, que se encuentran completamente capacitados para ofrecerles la mayor seguridad en cada uno de nuestros recorridos.</p>
            <hr />
            <h2><span ><strong>Misión</strong></span></h2>
            <p >somos una empresa de servicios comprometida en brindar a nuestros clientes y usuarios en general un servicio de transporte terrestre de pasajeros, tours turísticos y transporte empresarial con un nivel de alta calidad, comodidad, seguridad y puntualidad; satisfaciendo totalmente sus expectativas.</p>

        </div>
    )
}
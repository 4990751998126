
import { Parallax } from 'react-parallax'
import Sierra from './../img/parallax.jpg'
import Logo from './../img/mensajero.png'

import CSS from './../css/parallax.module.css'

export default function ParallaxUno(){

  
    return(
    <div className='row'>
        <Parallax bgImage={Sierra} strength={150}>
            <div style={{height:'400px'}}>
                <div className={CSS.insideStyle}>
                    <h2 style={{color:'#000',textAlign:'center'}}>Alvaro Santamaria Viajes y Turismo</h2>
                    <p style={{color:'#000',fontSize:'20px',textAlign:'center'}}>Viajes y Turismo, es una agencia de viajes con más de 25 años de experiencia en el sector transportador y turístico.</p>
                </div>
            </div>
        </Parallax>
    </div>
        )
}
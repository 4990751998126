
export default function Internacional() {


    return (
        <div >
            <div className="col-12 p-4">            
            <h2 className='text-center mt-2 mb-2'><span><strong>INTERNACIONAL</strong></span></h2>
            </div>

            <div className="w-100">
                <div className="card col-12 mb-2" >
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img className="card-img-top" src={require("./../img/internacional/peru.jpg")} alt="Card image cap" />
                        </div>
                        <div className='col-12 col-sm-8 p-4 ' style={{textAlign:'justify'}} >
                            <h5>TOUR INTERNACIONAL - PERÚ - MACHU PICCHU</h5>
                            <p>Perú es uno de los principales destinos turísticos en Sudamérica. Cuenta con una variada belleza natural según las diferentes zonas de su territorio, notables maravillas arqueológicas, como Machu Picchu, delicias gastronómicas, y un vasto patrimonio cultural, existe también gran variedad de actividades y lugares para ver y hacer en Perú.</p>
                            <p>Perú es un país que posee una diversidad y riqueza poco comunes en el mundo. Sus principales atractivos son su patrimonio arqueológico de las culturas precolombinas y el centro del Imperio Inca, su gastronomía, su arquitectura colonial (posee imponentes construcciones coloniales) y sus recursos naturales (un paraíso para el turismo ecológico).</p>
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="w-100">
                <div className="card col-12 mb-2" >
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img className="card-img-top" src={require("./../img/internacional/argentina.jpg")} alt="Card image cap" />
                        </div>
                        <div className='col-12 col-sm-8 p-4 ' style={{textAlign:'justify'}}  >
                            <h5>ARGENTINA - PUERTO DE IGUAZU - CATARATAS DE IGUAZU</h5>
                            <p>Localizadas entre Brasil y Argentina, las Cataratas del Iguazú están formadas por 275 saltos de agua de hasta 80 metros de altura que componen uno de los mayores espectáculos de la naturaleza.</p>
                        </div>
                    </div>

                </div>
            </div>



            <div className="w-100">
                <div className="card col-12 mb-2" >
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img className="card-img-top" src={require("./../img/internacional/brasil.jpg")} alt="Card image cap" />
                        </div>
                        <div className='col-12 col-sm-8 p-4 ' style={{textAlign:'justify'}} >
                            <h5>TOUR INTERNACIONAL - BRASIL</h5>
                            <p>Con una gastronomía exquisita, cultura riquísima, algunas de las playas más lindas del mundo, cascadas deslumbrantes y aventuras para todos los gustos. Uno puede elegir el tipo de turismo que le encanta y sorprenderse con las maravillas de Brasil.</p>
                        </div>
                    </div>

                </div>
            </div>


            <div className="w-100">
                <div className="card col-12 mb-2" >
                    <div className='row'>
                        <div className='col-12 col-sm-4'>
                            <img className="card-img-top" src={require("./../img/internacional/uruguay.jpg")} alt="Card image cap" />
                        </div>
                        <div className='col-12 col-sm-8 p-4 ' style={{textAlign:'justify'}} >
                            <h5>TOUR INTERNACIONAL - URUGUAY</h5>
                            <p>Descubre su cultura, increíbles lugares, historias y legendas con entretenidos e inolvidables guías locales</p>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )

}
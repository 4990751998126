import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function OtrosDestinos() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'mamancana.jpg', titulo: 'RESERVA NATURAL PRIVADA MAMANCANA', contenido: 'Las emociones no faltan en Santa Marta. Mamancana, una reserva de 600 hectáreas de bosque tropical seco ubicada en la vía entre la ciudad y el aeropuerto Simón Bolívar, es ideal para quienes disfrutan el contacto con la naturaleza. Allí, además de cabalgar, los visitantes pueden observar aves y practicar deportes de aventura entre los que están la escalada en roca, el ‘canopy’ y el parapente.' },
        { id: 2, folder: 'tours', imagen: 'ciudadp.jpg', titulo: 'CIUDAD PERDIDA', contenido: 'Llegar a Ciudad Perdida es una aventura inolvidable. Construido alrededor de 700 d. C., es el centro urbano más importante de la antigua civilización Tayrona y uno de los sitios arqueológicos más importantes de la investigación en esta cultura. Hay plazas circulares, escaleras, caminos y canales de piedra.' },
        { id: 3, folder: 'tours', imagen: 'pueblito2.jpg', titulo: 'PUEBLITO', contenido: 'xxPueblito es uno de los sitios más representativos de la cultura Tayrona. Este lugar del Parque Nacional Natural Tayrona cuenta con terrazas escalonadas recubiertas por fuera por muros de contención; también tiene construcciones de piedra en forma anular, caminos internos de grandes lozas talladas, escaleras y puentes construidos en piedra con diferentes tipos de diseño y grado de elaboración.xxxxx' },
        { id: 4, folder: 'tours', imagen: 'salamanca.jpg', titulo: 'PARQUE NATURAL ISLA DE SALAMANCA', contenido: 'El avistamiento de aves es la actividad preferida de quienes visitan el Vía Parque Natural Isla de Salamanca, ubicado en la jurisdicción de los municipios de Pueblo Viejo y Sitio Nuevo. Se trata de un conjunto de pequeñas islas formadas por acumulación de sedimentos del delta del río Magdalena sobre una antigua bahía. Tiene 56.200 ha con ecosistemas de bosques de manglar, entre otros.' },
        { id: 5, folder: 'tours', imagen: 'aracataca.jpg', titulo: 'ARACATACA-GABRIEL GARCIA MARQUEZ', contenido: 'En Aracataca, Colombia, el pueblo donde nació el Nobel de Literatura Gabriel García Márquez, se persibe su legado. Se conservan la estación del tren, uno de los lugares en los que se inspiró para crear ‘Cien años de soledad’; y la casa donde vivió, que hoy es un museo en el que se guardan objetos pertenecientes al escritor y donde se llevan a cabo actividades culturales. (Podrán conocer en el municipio de Aracataca, la Casa del Telégrafo)' },
       ];


    return (
        <div >

            <FormularioTour titulo='OTROS DESTINOS'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

        </div>
    )

}
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Menu from './components/modules/Menu';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/pages/Home';
import Nosotros from './components/pages/Nosotros';
import Contactenos from './components/pages/Contactenos';
import { useEffect } from 'react';
import Fondo from './components/img/fondo.jpg'
import CityTour from './components/tours/CityTour';
import Guajira from './components/tours/Guajira';
import Footer from './components/modules/Footer';
import PlayaCristal from './components/tours/PlayaCristal';
import DonDiego from './components/tours/DonDiego';
import Minca from './components/tours/Minca';
import Tayrona from './components/tours/Tayrona';
import Gairaca from './components/tours/Gairaca';
import Buritaca from './components/tours/Buritaca';
import Convenios from './components/pages/Convenios';
import Santuario from './components/tours/Santurario';
import Cartagena from './components/tours/Cartagena';
import Termales from './components/tours/Termales';
import Acuario from './components/tours/Acuario';
import BahiaConcha from './components/tours/BahiaConcha';
import Mompox from './components/tours/Mompox';
import Barranquilla from './components/tours/Barranquilla';
import OtrosDestinos from './components/tours/OtrosDestinos';
import NuestrosVehiculos from './components/pages/NuestrosVehiculos';
import Transporte from './components/pages/Transporte';
import CityTourDos from './components/tours/CityTourDos';
import CasaSM from './components/pages/CasaSM';
import Internacional from './components/tours/Internacional';
import Turismo from './components/tours/Turismo';

function App() {

  
  useEffect(()=>{
    
    document.title = "Santa Maria";
    document.body.style.backgroundImage=Fondo;
    });


  return (
    <div className="container bg-light"  >
      <BrowserRouter>      
      <Menu></Menu>
      <Switch>
        <Route exact path="/"><Home/></Route>
        <Route exact path="/nosotros"> <Nosotros/></Route>
        <Route exact path="/contactenos"> <Contactenos/></Route>
        <Route exact path="/citytour"> <CityTourDos/></Route>
        <Route exact path="/guajira"> <Guajira/></Route>
        <Route exact path="/neguanje"> <PlayaCristal/></Route>
        <Route exact path="/dondiego"> <DonDiego/></Route>
        <Route exact path="/minca"> <Minca/></Route>
        <Route exact path="/tayrona"> <Tayrona/></Route>
        <Route exact path="/gairaca"> <Gairaca/></Route>
        <Route exact path="/buritaca"> <Buritaca/></Route>
        <Route exact path="/convenios"> <Convenios/></Route>
        <Route exact path="/santuario"> <Santuario/></Route>
        <Route exact path="/cartagena"> <Cartagena/></Route>
        <Route exact path="/termales"> <Termales/></Route>
        <Route exact path="/acuario"> <Acuario/></Route>
        <Route exact path="/bahia-concha"> <BahiaConcha/></Route>
        <Route exact path="/mompox"> <Mompox/></Route>
        <Route exact path="/barranquilla"> <Barranquilla/></Route>
        <Route exact path="/otros-destinos"> <OtrosDestinos/></Route>
        <Route exact path="/vehiculos"> <NuestrosVehiculos/></Route>
        <Route exact path="/transporte"> <Transporte/></Route>
        <Route exact path="/casa-sm"> <CasaSM/></Route>
        <Route exact path="/internacional"> <Internacional/></Route>
        <Route exact path="/turismo"> <Turismo/></Route>


      </Switch>
      
      </BrowserRouter>
      
      <Footer></Footer>
    </div>
  );
}

export default App;

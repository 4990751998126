import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function Turismo() {


    const items = [
        { id: 1, folder: 'turismo', imagen: 'ecoturismo.jpg', titulo: 'ECOTURISMO', contenido: 'Es aquella forma de turismo especializado y dirigido que se desarrolla en áreas con un atractivo natural especial y se enmarca dentro de los parámetros del desarrollo humano sostenible. El ecoturismo busca la recreación, esparcimiento y la educación del visitante a través de la observación, el estudio de los valores naturales y de los aspectos culturales relacionados con ellos; por lo cual, es una actividad controlada y dirigida que produce un mínimo impacto sobre el ecosistemas naturales.' },
        { id: 2, folder: 'turismo', imagen: 'etnoturismo.jpg', titulo: 'ETNOTURISMO', contenido: 'es aquel especializado que se realiza en territorios de los grupos étnicos con fines culturales, educativos y recreativos que permite conocer los valores culturales , forma de vida , manejo ambiental costumbres de los grupos étnicos , así como aspectos de su historia.' },
        { id: 3, folder: 'turismo', imagen: 'agroturismo.jpg', titulo: 'AGROTURISMO', contenido: 'Es un tipo de turismo especializado en el cual el turista se involucra con el campesino en las labores agrícolas. Por sus características este tipo de turismo se desarrolla en actividades vinculadas a la agricultura, la ganadería, u otra actividad buscando con ello generar un ingreso adicional a la economía rural.' },
        { id: 4, folder: 'turismo', imagen: 'acuaturismo.jpg', titulo: 'ACUATURISMO', contenido: 'Tiene como motivación principal el disfrute por parte de los turistas de servicios de alojamiento, gastronomía y recreación, prestados durante el desplazamiento por ríos, mares, lagos y en general por cualquier cuerpo de agua así como de diversos atractivos turísticos que se encuentren en el recorrido utilizando para ello embarcaciones especialmente adecuadas para tal fin.' },
        { id: 5, folder: 'turismo', imagen: 'metropolitano.jpg', titulo: 'METROPOLITANO', contenido: 'Se realiza en los grandes centros urbanos, con fines culturales educativos y recreativos que dé lugar a la conservación del patrimonio histórico y cultural; a creación de espacios públicos de esparcimiento comunitario que propendan por el aprovechamiento sostenible de los recursos naturales urbanos.' },
    ];


    return (
        <div >

            <FormularioTour titulo='¿Qué es el turismo?'>
                <div  style={{textAlign:'justify'}}  >
                    <p >Es el conjunto de actividades que realizan las personas-turistas-durante sus viajes y estancias en lugares distintos de su entorno habitual, con fines entre otros de ocio, cultura, salud, eventos, convenciones o negocios. De acuerdo al desplazamiento de los viajeros, el turismo puede ser:</p>
                    <ul >
                        <li>Turismo emisor: el realizado por nacionales en el exterior</li>
                        <li>Turismo interno: el realizado por los residentes en el territorio económico del país.</li>
                        <li>Turismo receptivo: el realizado por los no residentes, en el territorio económico del país.</li>
                        <li>Excursionista: son aquellos no residentes que sin pernotar ingresan al país con un fin diferente al tránsito. </li>
                    </ul>
                    <h2><strong>¿Sabías que hay diferentes clases de turismo?</strong></h2>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

        </div>
    )

}
import Servicios from '../modules/Servicios';
import NosotrosContent from '../modules/NosotrosContenido';
import SliderCasaSM from '../modules/SliderCasaSM';
import SliderCasaSM2 from '../modules/SliderCasaSM2';
export default function CasaSM() {

    return (
        <div className='mb-4'>
            <div className="col-12 p-4">            
            <h2 className='text-center mt-2 mb-2'><span><strong>CASA SANTA MARIA</strong></span></h2>
            </div>

            <div className='row'>
            <div className='col-12 col-sm-6 mx-auto'>
                <p>Santa Marta 🌊ubicada a orillas del Mar Caribe te invita a un lugar lleno de magia y aventura.</p>
                <p>Nuestros servicios:</p>
                <p>
                    ✅Contamos con Caja de seguridad.<br />
                    ✅Puerta de seguridad<br />
                    ✅ Buen sector<br />
                    ✅ 1.500 metros del centro histórico y la Bahía<br />
                    ✅ 900 metros donde salen los buses para Palomino y Parque Tayrona<br />
                    ✅ 600 Metros, de la estación donde salen,  los carros para Minca.<br />
                    ✅ 1.100 metros Exito Libertador.<br />
                </p>

                <p>Contacto:<br />3117045300-3146667209 - Carrera 16b 10-72</p>
            </div>

            <div className='col-12 col-sm-6 mx-auto'>
                <SliderCasaSM></SliderCasaSM>
            </div>
            <div className='col-12 col-sm-6 mx-auto'>
                <hr></hr>
                <SliderCasaSM2></SliderCasaSM2>
            </div>
        </div>


        </div>
        
    )

}
import CardHorizontal from '../modules/CardHorizontal';
import FormItinerario from '../modules/FormItinerario';
import FormularioTour from '../modules/FormularioTour';


export default function BahiaConcha() {


    const items = [
        { id: 1, folder: 'tours', imagen: 'bahiaconcha.jpg', titulo: '', contenido: 'Ubicada a solo 30 minutos de Santa Marta, es una de las playas más visitadas por turistas y lugareños. Está rodeado de montañas y tiene unas vistas preciosas. La playa es grande, con mucha sombra en la orilla desde los árboles donde puedes colgar tus hamacas, o ubicar debajo de ellas tus carpas para sentir la brisa marina. No hay cabañas o edificios en Bahía Concha para quedarse, solo hay una zona de acampada con hamacas.' },
          ];


    return (
        <div >

            <FormularioTour titulo='BAHIA CONCHA'>
                <div>
                </div>
            </FormularioTour>


            <div className='container-fluid'>
                {
                    items.map(item => {
                        return (<CardHorizontal key={item.id}  {...item} />)
                    })
                }
            </div>

            <FormItinerario horario='9:00 am- recogida 4:00 pm ' titulo='Itinerario' >
                <div>
                    <p>Tour privado: Recogida en vehículo climatizado ida y regreso horario sugerido 9:00 am- recogida 4:00 pm</p>   
                    <p>Tour compartido: horario 9:00 am</p>                 
                    <strong>Incluye</strong>
                    <ul>
                        <li>Guía</li>
                        <li>Entrada</li>
                        <li>Seguros e impuestos</li>
                    </ul>
                </div>
            </FormItinerario>
        </div>
    )

}